<template>
  <div id="app">
    <router-view
      :couple="couple"
      :event="event"
      :live="live"
      :year="timer.year"
      :month="timer.month"
      :date="timer.date"
      :hour="timer.hour"
      :minute="timer.minute"
      :photos="photos"
      :phone="phone"
      :stories="stories"
      :dalil="dalil"
      :videos="videos"
      :calendar="calendar"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      phone: [
        {
          // male
          id: 1,
          name: "Rizal",
          number: "",
        },
        {
          // female
          id: 2,
          name: "Marsha",
          number: "6289681091020",
        },
      ],
      couple: {
        male: {
          nickname: "Rizal",
          fullname: "Fachkur Rizal",
          instagram: "",
          father: "Mansur",
          mother: "Maslihah",
          account: {
            id: "647301015921537",
            type: "BRI",
            name: "Fachkur Rizal",
          },
        },
        female: {
          nickname: "Marsha",
          fullname: "Marsha Salsabilah",
          instagram: "mhsya392_",
          father: "H. Nyono Subiantoro",
          mother: "Hj. Siti Mafulah",
          account: {
            id: "Dusun Gondang RT 08 RW 02, Desa Kepulungan, Kec. Gempol - Kab. Pasuruan, 67155",
            type: "Kirim Hadiah 🎁",
            name: "Marsha Salsabilah",
          },
        },
      },
      event: {
        marriage: {
          date: "16 September 2024",
          day: "Senin",
          start: "08.00",
          end: "10.00",
          location: {
            name: "Gerai Sasmito Gading (Wisata Air Panas Kepulungan)",
            link: "https://maps.app.goo.gl/b4kSVJDpoCkTWRJ37",
          },
          address:
            "Gondang, Kepulungan, Gempol, Pasuruan, Jawa Timur",
        },
        reception: {
          date: "16 September 2024",
          day: "Senin",
          start: "12.00",
          end: "16.00",
          location: {
            name: "Gerai Sasmito Gading (Wisata Air Panas Kepulungan)",
            link: "https://maps.app.goo.gl/b4kSVJDpoCkTWRJ37",
          },
          address:
            "Gondang, Kepulungan, Gempol, Pasuruan, Jawa Timur",
        },
      },
      live: {
        zoom: {
          meetid: "",
          password: "",
          link: "",
        },
        youtube: "https://www.youtube.com/",
      },
      timer: {
        year: 2024,
        month: 9,
        date: 16,
        hour: 12,
        minute: 0,
      },
      calendar: {
        title: "Pernikahan Marsha & Rizal",
        location: "Gerai Sasmito Gading (Wisata Air Panas Kepulungan)",
        details:
          "Gondang, Kepulungan, Gempol, Pasuruan, Jawa Timur",
        duration: 3, // Kira - kira pernikahan berlangsung 3 jam
      },
      photos: [
        {
          id: 1,
          file: require("@/assets/photos/gallery-1.jpg"),
        },
        {
          id: 2,
          file: require("@/assets/photos/gallery-2.jpg"),
        },
        // {
        //   id: 3,
        //   file: require("@/assets/photos/gallery-3.jpg"),
        // },
        // {
        //   id: 4,
        //   file: require("@/assets/photos/gallery-4.jpg"),
        // },
        // {
        //   id: 5,
        //   file: require("@/assets/photos/gallery-5.jpg"),
        // },
        // {
        //   id: 6,
        //   file: require("@/assets/photos/gallery-6.jpg"),
        // },
      ],
      videos: { url: "https://www.youtube.com/embed/6stlCkUDG_s" },
      dalil: {
        isi: "Dan di antara tanda-tanda kekuasaan-Nya ialah Dia menciptakan untukmu istri-istri dari jenismu sendiri, supaya kamu cenderung dan merasa tentram kepadanya, dan dijadikan-Nya di antaramu rasa kasih dan sayang. Sesungguhnya pada yang demikian itu benar-benar terdapat tanda-tanda bagi kaum yang berpikir.",
        sumber: "QS. Ar-Rum : 21",
      },
      stories: [
        {
          year: "First Meet",
          text: "21.07.2024",
        },
        {
          year: "Relationship",
          text: "22.07.2024",
        },
        {
          year: "Engagement",
          text: "28.07.2024",
        },
        {
          year: "Get Married",
          text: "16.09.2024",
        },
      ],
    };
  },
};
</script>

<style>
@import "./assets/styles.css";
</style>
