import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import VueScrollActive from "vue-scrollactive";
import VueClipboard from "vue-clipboard2";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";
import AOS from "aos";
import "aos/dist/aos.css";
import AddToCalendar from "vue-add-to-calendar";

Vue.use(AddToCalendar);

Vue.use(VueScrollActive);
Vue.use(VueClipboard);
Vue.use(VueToast, {
  position: "top",
  duration: 2000,
});

Vue.config.productionTip = false;

new Vue({
  created() {
    AOS.init();
  },
  router,
  render: (h) => h(App),
}).$mount("#app");
