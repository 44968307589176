import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: () => import("@/views/islami/Islami1.vue"),
  // },
  // {
  //   path: "/1",
  //   name: "Islami1",
  //   component: () => import("@/views/islami/Islami1.vue"),
  // },
  // {
  //   path: "/2",
  //   name: "Islami2",
  //   component: () => import("@/views/islami/Islami2.vue"),
  // },
  // {
  //   path: "/3",
  //   name: "Islami3",
  //   component: () => import("@/views/islami/Islami3.vue"),
  // },
  // {
  //   path: "/4",
  //   name: "Islami4",
  //   component: () => import("@/views/islami/Islami4.vue"),
  // },
  {
    path: "/",
    name: "Islami5",
    component: () => import("@/views/islami/Islami5.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
